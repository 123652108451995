@import "../../../css/constants.scss";

.table-wrapper {
  height: calc(100vh - 16rem);
  overflow: auto;
}

.table {
  height: 1px;
  vertical-align: middle;
}

.thead {
  background-color: $LIGHT_GRAY_6;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.th {
  border: 1px;
  padding: 0.5rem 0 !important;
}

.th:first-of-type {
  border-radius: 6px 0px 0px 0px;
  padding-right: 0rem !important;
  padding-left: 0.25rem !important;
}
.th:last-of-type {
  border-radius: 0px 6px 0px 0px;
}

.tr:hover {
  background-color: $LIGHT_LIGHT_BLUE;
}

.tr-disabled
{
  background: lightgray;
}

.td {
  padding: 2px !important;
}
.td:first-of-type {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
