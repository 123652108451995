@import "../../../css/constants.scss";

.svg {
  cursor: pointer;
  border: 1px solid $CIPIA_BLUE;
  border-radius: 5px;

  fill: $CIPIA_BLUE;

  &.disabled {
    border-color: $GRAY_5;
    fill: $GRAY_5;
    cursor: not-allowed;
  }

  &:not(.disabled) {
    &:hover {
      background-color: $LIGHT_LIGHT_BLUE;
    }
    &:active {
      background-color: $CIPIA_BLUE;
      fill: $LIGHT_GRAY_5;
    }
  }
}
