.left-button {
  border-radius: 1.5rem 0 0 1.5rem;
  border-right: none !important;
}

.center-button {
  border-radius: 0;
  border-right: none !important;
}

.right-button {
  border-radius: 0 1.5rem 1.5rem 0;
}
