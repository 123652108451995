@import "bootstrap/dist/css/bootstrap.min.css";
@import "./forms.scss";
@import "./constants.scss";
@import "./sec_nav_bar.css";
@import "../components/EventMap/EventMap.leflet.css";
@import "../css/button.scss";
@import "./dropdown.scss";
@import "./font.scss";
@import "./modal-form.scss";
@import "./tooltip.scss";
@import "./side-tree.scss";

body {
  overflow: hidden;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

/* Scrollbar Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b5beca #ffffff;
}

/* Scrollbar Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b5beca;
  border-radius: 8px;
  border: 0px solid #b5beca;
}

/* Util classes*/
.cursor-pointer {
  cursor: pointer;
}

.absolute-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

p.app-hint,
.app-hint {
  background-color: #eef2ff !important;
  color: #435fc5 !important;
}

.app-link {
  color: #2c449a !important;
  text-decoration: underline !important;
}

a {
  text-decoration: none;
}

.font-md {
  font-size: 0.875rem;
  line-height: 1.3;
}

.font-sm {
  font-size: 0.75rem;
}

.capitalized {
  text-transform: uppercase;
}

.capitalized-first {
  text-transform: capitalize;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.icon-xm {
  width: 1.5rem;
}

.icon-sm {
  width: 3rem;
}

.icon-md {
  width: 4.5rem;
}

.icon-lg {
  width: 6rem;
}

.icon-xl {
  width: 8rem;
}

.selected-row-bg {
  background-color: $LIGHT_LIGHT_BLUE;
}

.light-gray-5-bg {
  background-color: $LIGHT_GRAY_5 !important;
}

.rounded-top-left {
  border-top-left-radius: 6px;
}
.rounded-top-right {
  border-top-right-radius: 6px;
}
.rounded-bottom-left {
  border-bottom-left-radius: 6px;
}
.rounded-bottom-right {
  border-bottom-right-radius: 6px;
}

.box-shadow-no-right {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  clip-path: inset(-6px 0px -6px -6px);
}
.box-shadow-no-left {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  clip-path: inset(-6px -6px -6px 0px);
}
.box-shadow-no-top {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  clip-path: inset(0px -6px -6px -6px);
}

.input-with-label-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}
.input-label {
  font-size: 0.875rem;
  color: $LABEL_COLOR;
}
.cipia-blue-on-hover:hover {
  color: $CIPIA_BLUE !important;
}
th {
  font-weight: normal;
}
.modal-yes-no-dialog {
  max-width: 37.5rem !important;
  width: 37.5rem !important;
}
.absolute-centered-axis-x {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
