@import "../../css/constants.scss";

.container {
  height: 100%;
  // margin-bottom: 1rem;
}

.form-container {
  width: 34rem;
  height: 36rem;
  margin: 1rem;
  padding: 2rem 5rem;
  border-radius: 1rem;
  background-color: $LIGHT_LIGHT_GRAY;
}

.format-selected-display {
  background-color: $LIGHT_LIGHT_BLUE;
  height: 10rem;
  width: 80%;
  border-radius: 0.5rem;
  margin: 1rem 0;
}